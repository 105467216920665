<template>
  <div class="rilevazioni-container">
    <sidebar :noSocial="true" :backOn="true" />
    <div class="device-container">
      <deviceCard :small=true :content="newData" />
      <div class="xs-icon-card">
        <img src="@/assets/lavaggio.png">
        <p>{{ $t('pressureReport') }}</p>
      </div>
    </div>
    <div class="dashboard-container">
      <IdroTitle :title="title" />
      <div class="content">
        <div class="header">
          <div class="date-filter">
            <DatePicker @change="dateFiltering()" v-model="startDate" />
            <label>AL</label>
            <DatePicker @change="dateFiltering()" v-model="endDate" />
          </div>
          <div class="search-field">
            <SearchField v-model="searchValue" />
          </div>
        </div>
        <div class="table-wrap">
          <EasyDataTable table-class-name="customize-table" alternating buttons-pagination no-hover :loading="isLoading"
            :headers="headers" :items="reportData" :search-field="searchField" :search-value="searchValue"
            :rows-items="[5, 10, 20, 30, 40, 50]" :rows-per-page="5" theme-color="#1363df">
            <template #loading>
              <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
            </template>
          </EasyDataTable>
          <div v-if="isLoading" class="flex justify-center  mb-6 bg-white p-6 rounded-md mt-4 w-full">
            <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
          </div>
          <div v-if="!isLoading && pressureReportIsEmpty || pressureReportStatus.isError"
            class="flex justify-center mb-6 bg-white p-6 rounded-md mt-4 w-full">
            <h1 v-if="!pressureReportStatus.isError" class="font-semibold">NO DATA</h1>
            <h1 v-if="pressureReportStatus.isError" class="font-semibold">{{ pressureReportStatus.message }}</h1>
          </div>
          <div id="chart" class="mb-6 bg-white p-6 rounded-md mt-4 w-full"
            v-show="!isLoading && !pressureReportIsEmpty && !pressureReportStatus.isError"></div>

          <download-csv :class="{ 'restrictedAccess': devicesStore.deviceData.role == 'user' }"
            class="btn btn-default mt-6 justify-end flex" :data="reportData" :name="fileName">
            <div class="button-wrapper">
              <IveButton label="Export CSV" />
            </div>
          </download-csv>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDevicesStore } from '@/stores/DevicesStore'
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, computed, onMounted, onUnmounted, ref } from '@vue/runtime-core'
import SearchField from '@/components/input/searchField.vue'
import DatePicker from '@/components/input/datePicker.vue'
import IveButton from '@/components/input/iveButton.vue'
import { toInteger } from 'lodash'
import ApexCharts from "apexcharts";
import { useI18n } from 'vue-i18n'
import { useReportStore } from '@/stores/ReportStore'


const { t } = useI18n()

//props
const props = defineProps({
  id: String
})

//asynchronus component
const deviceCard = defineAsyncComponent(
  () => import('@/components/cards/deviceCard.vue'),
)
var options = {
  chart: {
    type: "area",
    height: 300,
    foreColor: "#999",
    stacked: false,
    dropShadow: {
      enabled: true,
      enabledSeries: [0],
      top: -2,
      left: 2,
      blur: 5,
      opacity: 0.06
    }
  },
  stroke: {
    curve: "smooth",
    width: 3
  },
  dataLabels: {
    enabled: false
  },
  series: [],
  markers: {
    size: 0,
    strokeColor: "#fff",
    strokeWidth: 3,
    strokeOpacity: 1,
    fillOpacity: 1,
    hover: {
      size: 6
    }
  },
  xaxis: {
    type: "datetime",
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {

    title: {
      text: 'Pressure (Bar)'  // Y-axis label with units
    },
    labels: {
      offsetX: 14,
      offsetY: -5
    },
  },
  grid: {
    padding: {
      left: -5,
      right: 5
    }
  },
  tooltip: {
    x: {
      formatter: function (val) {
        return new Date(val).toLocaleString(); // Formats the tooltip with date and time
      }
    }
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left'
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [0, 90, 100]
    }
  }
}
// var options = {
//   chart: {
//     height: 380,
//     width: "100%",
//     type: "line",
//     dropShadow: {
//       enabled: true,
//       enabledSeries: [0],
//       top: -2,
//       left: 2,
//       blur: 5,
//       opacity: 0.06
//     },
//     zoom: {
//       enabled: true
//     },
//     animations: {
//       initialAnimation: {
//         enabled: false
//       }
//     }
//   },
//   stroke: {
//     width: 3,
//     curve: 'smooth'
//   },
//   series: [],
//   xaxis: {
//     type: 'datetime'
//   },
//   tooltip: {
//     x: {
//       formatter: function (val) {
//         return new Date(val).toLocaleString(); // Formats the tooltip with date and time
//       }
//     }
//   }
// };

//state
const devicesStore = useDevicesStore()
const reportStore = useReportStore()
const { isLoading, pressureReport, pressureReportIsEmpty, pressureReportStatus } = storeToRefs(useReportStore())
const reportData = ref([])
const searchValue = ref()
const searchField = 'tipoAlarme'
const title = ref()

//date filter init
let MINIMUM_DIGIT = 2
let date = new Date()
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
let tmpFirstYear = String(firstDay.getFullYear())
let tmpFirstMonth = String((firstDay.getMonth() + 1))
let tmpFirstDay = String(firstDay.getDate())

tmpFirstDay = tmpFirstDay.padStart(MINIMUM_DIGIT, "0")
tmpFirstMonth = tmpFirstMonth.padStart(MINIMUM_DIGIT, "0")

const startDate = ref(String(tmpFirstYear + '-' + tmpFirstMonth + '-' + tmpFirstDay))

let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
let tmpLastYear = String(lastDay.getFullYear())
let tmpLastMonth = String((lastDay.getMonth() + 1))
let tmpLastDay = String(lastDay.getDate())

tmpLastDay = tmpLastDay.padStart(MINIMUM_DIGIT, "0")
tmpLastMonth = tmpLastMonth.padStart(MINIMUM_DIGIT, "0")

const endDate = ref(String(tmpLastYear + '-' + tmpLastMonth + '-' + tmpLastDay))
const fileName = ref(String(startDate.value + '_' + endDate.value) + '_Pressure_Report_.csv')

//change date format to ISOString
const start = computed(() => {
  let tmpStart = startDate.value.split('-')
  let tmpYear = tmpStart[0]
  let tmpMonth = tmpStart[1]
  let tmpDay = tmpStart[2]
  console.log(tmpStart)
  return new Date(tmpYear, tmpMonth - 1, tmpDay, '00', '00', '00').toISOString()
  // return new Date(Date.UTC(tmpYear, tmpMonth-1, tmpDay, '00', '00', '00')).toISOString()
})
const end = computed(() => {
  let tmpEnd = endDate.value.split('-')
  let tmpYear = tmpEnd[0]
  let tmpMonth = tmpEnd[1]
  let tmpDay = tmpEnd[2]
  console.log(tmpEnd)
  return new Date(tmpYear, tmpMonth - 1, tmpDay, '24', '00', '00').toISOString()
  // return new Date(Date.UTC(tmpYear, tmpMonth-1, tmpDay, '24', '00', '00')).toISOString()
})

const pressureReportParams = ref({
  device_code: null,
  start: start,
  end: end
})

function fillTableData() {
  if (chart) {
    reportData.value = []
    var chartData = []
    if (pressureReport.value !== undefined) {
      pressureReport.value.map((data, index) => {
        if (data._value !== undefined) {
          let newObj = {
            date: new Date(data._time).toLocaleString(),
            pressure: String(data._value) + ' Bar',
          }
          let newObj2 = {
            date: toInteger(new Date(data._time).getTime()),
            pressure: toInteger(data._value),
          }
          chartData.push(newObj2)
          reportData.value.push(newObj)
        }
      })
      options.series = []
      options.series.push({
        name: 'Pressure',
        data: chartData.map(obj => [obj.date, obj.pressure])
      })
    }
    chart.updateSeries(options.series)
    console.log('chart-data', chartData)
    console.log('table-data', reportData.value)
  } else {
    console.error('Chart instance is not available.')
    return
  }
}

let loadPressureReportController = new AbortController()

async function getHistoryData() {
  await reportStore.loadPressureReport(pressureReportParams.value, loadPressureReportController.signal)
  fillTableData()
}

function dateFiltering() {
  pressureReportParams.value.start = start
  pressureReportParams.value.end = end
  fileName.value = String(startDate.value + '_' + endDate.value) + '_Pressure_Report_.csv'
  getHistoryData()
}

let chart
onMounted(async () => {
  await devicesStore.loadDevice(props.id)
  pressureReportParams.value.device_code = devicesStore.deviceData.code
  title.value = 'Idrosat:' + devicesStore.deviceData.name
  dateFiltering()
  const chartElement = document.querySelector("#chart");
  if (chartElement) {
    chart = new ApexCharts(chartElement, options)
    chart.render()
  }
})

onUnmounted(() => {
  loadPressureReportController.abort()
  if (chart) {
    chart.destroy()
    chart = null
    console.log('chart destroyed', chart)
  }
})

const newData = computed(() => {
  return [devicesStore.deviceData]
})

//Table Data

const headers = [
  { text: "Date Time", value: "date", sortable: true, width: 100 },
  { text: t('pressure'), value: "pressure", sortable: true, width: 80 },
]

</script>

<style scoped>
.rilevazioni-container {
  @apply relative flex flex-col px-[16px] md:px-[200px] lg:px-[260px] xl:px-[320px] 2xl:px-[360px]
}

.dashboard-container {
  @apply flex flex-col w-full justify-center items-center
}

.device-container {
  @apply flex sm:flex-col fixed items-end sm:items-center gap-2 bottom-0 left-4 pb-4 sm:pb-8
}



.content {
  @apply flex flex-col justify-center gap-2 w-full sm:gap-4 sm:my-[20px]
}

.header {
  @apply flex gap-2 sm:justify-between flex-col sm:flex-row h-full
}

.table-wrap {
  @apply h-[380px] sm:h-full overflow-auto sm:overflow-visible
}

.search-field {
  @apply relative h-[24px] sm:w-[300px] sm:h-[40px]
}


.date-filter {
  @apply relative items-center flex gap-2 w-full h-[24px] sm:w-[500px] sm:h-[40px]
}

.date-filter label {
  @apply text-[12px] sm:text-[16px] relative text-white
}

.button-wrapper {
  @apply relative h-full w-full sm:w-[100px] sm:h-[40px] max-w-[80px] sm:max-w-[100px]
}

.customize-table {
  --easy-table-header-font-size: 10px;
  --easy-table-header-background-color: #DFEAFB;
  --easy-table-row-border: 1px solid #D2DDEE;
  --easy-table-header-height: 30px;

  --easy-table-body-row-background-color: #DFEAFB;
  --easy-table-body-even-row-background-color: #D2DDEE;
  --easy-table-body-row-font-size: 10px;
  --easy-table-body-row-height: 20px;

  --easy-table-footer-font-size: 8px;
  --easy-table-footer-height: 30px;
  --easy-table-footer-background-color: #F5F8FA;
  --easy-table-footer-font-color: #3A3A3E;

}

@media only screen and (min-width: 1536px) {
  .customize-table {
    --easy-table-header-font-size: 16px;
    --easy-table-header-background-color: #DFEAFB;
    --easy-table-header-height: 60px;
    --easy-table-row-border: 2px solid #D2DDEE;

    --easy-table-body-row-background-color: #DFEAFB;
    --easy-table-body-even-row-background-color: #D2DDEE;
    --easy-table-body-row-font-size: 16px;
    --easy-table-body-row-height: 60px;

    --easy-table-footer-font-size: 14px;
    --easy-table-footer-height: 50px;
    --easy-table-footer-background-color: #F5F8FA;
    --easy-table-footer-font-color: #3A3A3E;

  }
}
</style>
