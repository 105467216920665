<template>
  <div class="modal">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>



.modal {
  @apply  m-auto bg-white p-8 text-left drop-shadow-md flex flex-col box-border text-white 
  text-sm 
  transition-all ease-in-out duration-200 transform
  sm:w-[400px] w-[320px]
  sm:rounded-[40px] rounded-3xl
          /* w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-[360px] lg:h-[464px] */
}

.modal {
  background: linear-gradient(45.06deg, #010AD1 -8.2%, #1BF728 108.15%);
  border: 2px solid #FFEE58;
}
</style>