<template>
  <loading :loading="isLoading" />
  <div class="flussi-container">
    <sidebar :noSocial="true" :backOn="true" />
    <div class="device-container">
      <deviceCard :small=true :content="newData" />
      <div class="xs-icon-card">
        <img src="@/assets/ev_radio_status.png">
        <p>{{ $t('evRadioStatus') }}</p>
      </div>
    </div>
    <div class="content">
      <IdroTitle :title="title" />
      <div class="main">
        <table class="table-container">
          <thead>
            <tr>
              <th>
                <span>{{ $t('idSerial') }}</span>
              </th>
              <th>
                <span>{{ $t('group') }}</span>
              </th>
              <th>
                <span>{{ $t('batteryLevel') }}</span>
              </th>
              <th>
                <span>{{ $t('radioSignal') }}</span>
              </th>
              <th>
                <span>{{ $t('goodErrorData') }}</span>
              </th>
              <th>
                <span>{{ $t('errorPercentage') }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ev in radioEventStat">
              <td>
                <p>{{ ev.serialId }}</p>
              </td>
              <td>
                <p>{{ ev.group }}</p>
              </td>
              <td>
                <p>{{ ev.batteryLevel }}</p>
              </td>
              <td>
                <p>{{ ev.signal }}</p>
              </td>
              <td>
                <p>{{ ev.goodData }} - {{ ev.errorData }}</p>
              </td>
              <td>
                <p>{{ ev.errorPercentage }} %</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="w-fit mt-10">
          <MyButton @click="onSubmit()" type="button" :loading="postControlIsLoading" class="filled" label="Refresh Status" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDevicesStore } from '@/stores/DevicesStore'
import { useDataStore } from '@/stores/DataStore';
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, computed, onMounted, ref, onUnmounted } from '@vue/runtime-core'
import MyButton from '@/components/button/BaseButton.vue'
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
//props
const props = defineProps({
  id: String
})

//asynchronus component
const deviceCard = defineAsyncComponent(
  () => import('@/components/cards/deviceCard.vue'),
)
//state
const devicesStore = useDevicesStore()
const dataStore = useDataStore()
const { radioEventStat } = storeToRefs(useDataStore())
const { isLoading } = storeToRefs(useDevicesStore())

const newData = computed(() => {
  return [devicesStore.deviceData]
})
const title = ref()

function generateAddressString(start, end) {
  let addresses = [];
  for (let i = start; i <= end; i++) {
    addresses.push(`S${i}`);
  }
  return addresses.join(',');
}

const radioEventStatParams = ref({
  fields: generateAddressString(5700, 5795),
  measurement: 'RADIOEVSTAT',
  device_code: null
})

const postRadioEventStat = ref({
  command: 'RADIOEVSTAT?',
})

let dataInterval = null

async function getData() {
  await dataStore.getLastRadioEventStat(radioEventStatParams.value)
}

onMounted(async () => {
  await devicesStore.loadDevice(props.id)
  radioEventStatParams.value.device_code = devicesStore.deviceData.code
  title.value = 'Idrosat:' + devicesStore.deviceData.name
  getData()
  dataInterval = setInterval(getData, 5000)
})

onUnmounted(() => {
  clearInterval(dataInterval)
})

function onSubmit() {
  dataStore.postRawControl(devicesStore.deviceData.code, postRadioEventStat.value)
}

</script>

<style scoped>
.flussi-container {
  @apply relative flex flex-col px-[16px] md:px-[200px] lg:px-[260px] xl:px-[320px] 2xl:px-[360px]
}

.main {
  @apply flex flex-col w-full justify-center items-center relative
}

.device-container {
  @apply flex sm:flex-col fixed items-end sm:items-center gap-2 bottom-0 left-4 pb-4 sm:pb-8
}

.content {
  @apply flex flex-col justify-center gap-2 w-full sm:gap-4 sm:my-[20px]
}


input[type=text], input[type=password], input[type=number] {
  @apply pl-2 rounded w-20 sm:w-60 py-2 sm:py-3
}

input[type=radio] {
  @apply cursor-pointer
}

.dropdown {
  @apply pl-2 rounded w-20 sm:w-60 py-2 sm:py-3 cursor-pointer
}

.button-wrapper {
  @apply mt-3 flex w-[100px] mx-auto
}


/* Table Style */
.table-container {
  @apply w-full relative h-[300px] sm:h-full overflow-auto sm:overflow-visible
}

.table-container table {
  @apply mb-10 w-full
}

input:disabled, select:disabled {
  @apply cursor-default
}


.table-container th {
  @apply font-semibold py-[10px] px-[10px] text-left sticky top-0 border-b border-[#3a3a3e] bg-[#DDE8FA]/60 backdrop-blur-lg z-10
}

.table-container td {
  @apply py-[10px] px-[10px] bg-[#DDE8FA]/60 backdrop-blur-lg
}

.table-container th, td {
  @apply text-[10px] md:text-[16px]
}

.table-container tr {
  @apply text-left
}

.field-wrapper {
  @apply flex py-2 px-4 items-center
}
</style>